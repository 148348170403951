import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../containers/layout';
import ComponentPicker from '../../components/componentPicker';
import Hero from '../../components/hero';
import keygen from '../../utils/keygen';

import './style.scss'

const IndexPage = ({ data, location }) => {
  const post = data.contentfulPost;
  return (
    <Layout 
    title={post.title} 
    description={post.excerpt ? post.excerpt.excerpt : ""}
    category={post.category}
    location={location}
    >
        <Hero 
        title={post.title}
        date={post.date}
        bgcolor='yellow' 
        img={post.heroImage ? post.heroImage : ''}
        heroStyle="post"
        excerpt={post.excerpt ? post.excerpt.excerpt : ""} 
        category={post.category}
        path={location.pathname}
        heroImageBottom={post.heroImageBottom}
        showDate={post.showDate}
        />
       
        {post.components ? post.components.map(component => {
            return (<ComponentPicker postSlug={post.slug} key={keygen()} type={component.internal.type} component={component} />);
          }): null}


    </Layout>
  )
}

export default IndexPage


export const pageQuery = graphql`
  query currentPostQuery($id: String!) {
    contentfulPost(id: { eq: $id }) {
      id
      title 
      slug
      category
      date
      showDate
      keywords
      excerpt {
        excerpt
      }
      heroImageBottom
      heroImage {
        description
        resolutions (quality: 100, width: 1000) {
          src
        }
      }
      components {
        __typename
        ... on ContentfulArticleContent {
        title
        slug
        internal {
          type
        }
      }
      __typename
      ... on ContentfulRelatedPosts {
      title
      slug
      style
      internal {
        type
        }
      }
      __typename
      ... on ContentfulPeople {
      title
      slug
      internal {
        type
        }
      }
      __typename
      ... on ContentfulPromoBlock {
      title
      slug
      internal {
        type
      }
      }
      __typename
      ... on ContentfulNumbers {
      title
      slug
      internal {
        type
      }
      }
      __typename
      ... on ContentfulVideo {
      id
      slug
      title
      internal {
        type
        }
      }
      __typename
      ... on ContentfulTestimonial {
      id
      slug
      title
      internal {
        type
        }
      }
      __typename
      ... on ContentfulColumnContent {
      title
      slug
      internal {
        type
      }
      }
      __typename
      ... on ContentfulSomeAndKeywordsDisplay {
      id
      slug
      title
      internal {
        type
        }
      }

    }     
  }  
}
`